

<template>
    <div class="row">
        <div class="col-12 mt-1">
            <div class="d-flex mt-0 mb-1" style="width:100%">
                <!-- MatrixSize -->
                <div class="dropdown me-2">
                    <button 
                        class="btn btn-sm btn-outline-primary dropdown-toggle" 
                        type="button" 
                        id="matrixSizeDD" 
                        data-bs-toggle="dropdown" 
                        aria-haspopup="true" 
                        aria-expanded="false" 
                        style="cursor: pointer;"
                        :disabled="props.TooManyIDs"
                    >
                        <i class="bi bi-arrows-fullscreen me-1" aria-hidden="true"></i> {{ $t('Matrix Size') }}
                    </button>
                    <div class="dropdown-menu" aria-labelledby="matrixSizeDD">
                        <button 
                            class="dropdown-item matrix-size" 
                            msize="small" 
                            @click="(e) => { emitAndSetDropdownElementActive(e); }"
                        >{{ $t('Small') }}</button>

                        <button 
                            class="dropdown-item matrix-size active" 
                            msize="medium" 
                            @click="(e) => { emitAndSetDropdownElementActive(e); }"
                        >{{ $t('Medium') }}</button>

                        <button 
                            class="dropdown-item matrix-size" 
                            msize="large" 
                            @click="(e) => { emitAndSetDropdownElementActive(e); }"
                        >{{ $t('Large') }}</button>
                    </div>
                </div>

                <!-- Options -->
                <div class="dropdown">
                    <button 
                        class="btn btn-sm btn-outline-primary dropdown-toggle" 
                        type="button" 
                        data-bs-auto-close="outside" 
                        data-bs-toggle="dropdown" 
                        id="matrixOptionsDD" 
                        aria-haspopup="true" 
                        aria-expanded="false" 
                        style="cursor: pointer;"
                        :disabled="props.TooManyIDs"
                    >
                        <i class="bi bi-gear-fill" aria-hidden="true"></i> {{ $t('Options') }}
                    </button>
                    <div class="dropdown-menu" aria-labelledby="matrixSizeDD" style="width:275px;">
                        <h1 class="dropdown-header">{{ $t('Frequency Label') }}</h1>
                        <div class="form-check ms-2">
                            <input 
                                class="form-check-input" 
                                type="checkbox" 
                                value="ShowFrequencyValue" 
                                v-model="showFrequencyValue" 
                                @change="(e) => { setCheckboxEmits(e) }" 
                                id="showFrequencyValue"
                            > 
                            <label class="form-check-label" :title="$t('Show frequency value')" for="showFrequencyValue">
                                {{ $t('Show frequency value') }}
                            </label>
                        </div>
                        <div class="form-check ms-2">
                            <input 
                                class="form-check-input" 
                                type="checkbox" 
                                value="ShowFrequencyText" 
                                v-model="showFrequencyText" 
                                @change="(e) => { setCheckboxEmits(e) }" 
                                id="showFrequencyText"
                            >
                            <label class="form-check-label" :title="$t('Show frequency text')" for="showFrequencyText">
                                 {{ $t('Show frequency text') }}
                            </label>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script setup lang="ts">
    // import { getOrCreateDataObject, deleteDataObject } from 'o365-dataobject';
    import { ref } from 'vue';
    import { $t } from 'o365-utils';

    const showFrequencyValue = ref(true);
    const showFrequencyText = ref(false);
    
    const emits = defineEmits([
        "MatrixSize", 
        "ShowFrequencyValue",
        "ShowFrequencyText"
    ]);

    const props = defineProps({
        "TooManyIDs": { type: Boolean, default: false }
    });

    const emitAndSetDropdownElementActive = (element) => {
        const size = element.target.attributes[1].value; // msize attribute

        switch(size) {
            case 'small':
                emits('MatrixSize', { size: size, class: 'table-matrix-sm'});
                break;
            case 'medium':
                emits('MatrixSize', { size: size, class: 'table-matrix-md'});
                break;
            case 'large':
                emits('MatrixSize', { size: size, class: 'table-matrix-lg'});
                break;
            default: 
                emits('MatrixSize', { size: 'medium', class: 'table-matrix-md'});
                break;
        }

        document.getElementsByClassName('matrix-size active')[0].classList.remove('active');
        element.srcElement.classList.add('active');
    }

    const setCheckboxEmits = (element) => {
        const el = element.target;

        if(el.checked) {
            emits(el.value, true);
        } else {
            emits(el.value, false);
        }
    }
</script>